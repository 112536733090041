import { useQuery } from 'urql';

import { GET_CURRENT_USER_ACCOUNT } from 'lib/graphql/queries';

export type ADMStage =
  | 'identified'
  | 'lost'
  | 'account-managed'
  | 'pitch-booked'
  | 'pitch-executed'
  | 'onboarding-booked'
  | 'catering-booked';

export type CurrentUser = {
  email?: string;
  firstName?: string;
  lastName?: string;

  owner?: {
    deliveryAddressStreetAndNumber?: string;
    deliveryAddressPostalCode?: string;
    deliveryAddressCity?: string;
    admStage: ADMStage | null;
    deliveryAddressCountry?: string;
    companyName?: string;
    salesforceCustomer?: {
      isManagedAccount?: boolean;
      phoneNumber?: string;
      billingAddress?: {
        street?: string;
        postalCode?: string;
        city?: string;
        country?: string;
      };
    };
  };
  uuid: string;
};

export default function useCurrentUser() {
  const [{ data, fetching }, reexecuteQuery] = useQuery<{
    currentUserAccount: unknown;
  }>({
    query: GET_CURRENT_USER_ACCOUNT,
    requestPolicy: 'cache-and-network',
  });

  return {
    currentUserAccount:
      (data?.currentUserAccount as CurrentUser | null) || null,
    loading: fetching,
    refetch: reexecuteQuery,
  };
}
