import { gql } from 'urql';

export const GET_CURRENT_USER_ACCOUNT = gql`
  query currentUserAccount {
    currentUserAccount {
      uuid
      email
      firstName
      lastName
      phone
      owner {
        ... on Customer {
          id
          companyName
          admStage
          deliveryAddressStreetAndNumber
          deliveryAddressPostalCode
          deliveryAddressCity
          deliveryAddressCountry
          salesforceCustomer {
            isManagedAccount
            billingAddress {
              street
              postalCode
              city
              country
            }
            phoneNumber
          }
        }
      }
    }
  }
`;

export const GET_SALESFORCE_ACCOUNT_FROM_CUSTOMER_ACCOUNT = `
  query customerAccountSalesforceAccountId($uuid: ID!) {
    customerAccountSalesforceAccountId(uuid: $uuid)
  }
`;

export const GET_CUSTOMER_CONTACT_ID_FROM_OPPORTUNITY = `
  query opportunityCustomerContactId($opportunityId: ID!) {
    opportunityCustomerContactId(opportunityId: $opportunityId)
  }
`;
